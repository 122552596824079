<template>
  <section class="w-form-card" :class="{ 'w-form-card--rotate': rotating }">
    <h3 class="w-form-card__title">
      {{ $t("label.client_info") }}
    </h3>

    <!-- <b-container fluid> -->
    <form-wrapper :validator="$v.client">
      <b-form class="form-box form-box--mini">
        <b-row no-gutters>
          <b-col cols="12">
            <form-group
              label-for="name"
              attribute="input.user_name"
              name="name"
            >
              <template slot-scope="{ attrs }">
                <b-form-input
                  v-model.trim="client.name"
                  class="form-box__input"
                  v-bind="attrs"
                  type="text"
                  :disabled="!isActive"
                  :placeholder="$t('input.user_name')"
                  @input="$v.client.name.$touch()"
                />
              </template>
            </form-group>
          </b-col>
          <b-col cols="12">
            <form-group label-for="email" attribute="input.email" name="email">
              <template slot-scope="{ attrs }">
                <b-form-input
                  v-model.trim="client.email"
                  class="form-box__input"
                  v-bind="attrs"
                  type="email"
                  :disabled="!isActive"
                  :placeholder="$t('input.email')"
                  @input="$v.client.email.$touch()"
                />
              </template>
            </form-group>
          </b-col>
          <b-col cols="4">
            <form-group
              label-for="country_code"
              attribute="input.country_code"
              name="country_code"
            >
              <template slot-scope="{ attrs }">
                <b-form-input
                  v-model.trim="client.country_code"
                  class="form-box__input"
                  v-bind="attrs"
                  :disabled="!isActive"
                  :placeholder="$t('input.country_code')"
                  @input="$v.client.country_code.$touch()"
                />
              </template>
            </form-group>
          </b-col>

          <b-col cols="7" offset="1">
            <form-group label-for="phone" attribute="input.phone" name="phone">
              <template slot-scope="{ attrs }">
                <b-form-input
                  v-model.trim="client.phone"
                  class="form-box__input"
                  v-bind="attrs"
                  type="number"
                  :disabled="!isActive"
                  :placeholder="$t('input.phone')"
                  @input="$v.client.phone.$touch()"
                />
              </template>
            </form-group>
          </b-col>
        </b-row>
      </b-form>
    </form-wrapper>

    <!-- </b-container> -->

    <section class="w-form-card__actions">
      <b-button
        class="button button--filled"
        :disabled="$v.$invalid"
        @click="save"
        >{{ $t("button.finish") }}</b-button
      >
    </section>

    <!-- Disability Layer -->
    <section v-if="!isActive" class="w-form-card__overlay--disabled"></section>
    <!-- Disability Layer -->

    <section
      class="w-form-card__overlay justify-content-center"
      :class="{ 'w-form-card__overlay--show': editMode }"
    >
      <div class="text-center mb-3">
        <b-spinner
          style="width: 5rem; height: 5rem;"
          variant="light"
        ></b-spinner>
      </div>
    </section>
  </section>
</template>

<script>
import {
  required,
  email,
  maxLength,
  minLength
} from "vuelidate/lib/validators";
import { RotatingCard } from "@/constants/Mixins/RotatingCard";

// country code validation -------------------
const codeValidation = value => {
  let patt = /(^00[0-9])|(^\+[0-9])/;
  let pattNot = /[a-z]/;
  if (patt.test(value)) {
    return pattNot.test(value) ? false : true;
  } else {
    return false;
  }
};

export default {
  name: "ClientInfo",
  mixins: [RotatingCard],

  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    disableEdits: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      client: {
        name: "",
        phone: null,
        country_code: null,
        email: ""
      }
    };
  },
  watch: {
    disableEdits: {
      handler(newValue) {
        if (!newValue) {
          this.edit();
        }
      }
    }
  },
  methods: {
    save() {
      this.$emit("clientInfoFilled", this.client);
      this.rotate();
    },
    edit() {
      this.rotateBack();
    }
  },
  validations() {
    return {
      client: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(50)
        },
        phone: {
          minLength: minLength(9),
          maxLength: maxLength(14)
        },
        country_code: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(5),
          codeValidation
        },
        email: {
          email
        }
      }
    };
  }
};
</script>
